import styled, {createGlobalStyle} from 'styled-components';

export const GlobalStylePage = createGlobalStyle`
  @media(min-width: 300px) and (max-width: 767px){
    .img-obra{
      width: 100%;
      height: auto;
    }
    .speech-bubble-mini{
      margin-top: -390px;
      margin-left: 105px;
    }
  }
  @media(min-width: 768px) and (max-width: 1023px){
    .img-obra{
      width: 615px;
      height: 385px;
    }
    .speech-bubble-mini{
      margin-top: -410px;
      margin-left: 265px;
    }
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    .img-obra{
      width: 100%;
    }
    .speech-bubble-mini{
      margin-top: -540px;
      margin-left: 380px;
    }
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    .img-obra{
      width: 100%;
    }
    .speech-bubble-mini{
      margin-top: -235px;
      margin-left: 470px;
    }
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    .img-obra{
      width: 100%;
    }
    .speech-bubble-mini{
      margin-top: -260px;
      margin-left: 520px;
    }
  }
  @media(min-width: 1920px){
    .img-obra{
      width: 100%;
    }
    .speech-bubble-mini{
      margin-top: -320px;
      margin-left: 730px;
    }
  }
`
export const ContArrow = styled.div`
  width: 100%;
  height: 40px;
  z-index: 999;
`
export const ContImgHombreVolando = styled.div`
  z-index: 99;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 110px;
    height: auto;
    margin-top: -320px;
    margin-left: -220px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 160px;
    height: auto;
    margin-top: -400px;
    margin-left: -370px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 160px;
    height: auto;
    margin-top: -540px;
    margin-left: -370px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 200px;
    height: auto;
    margin-top: -190px;
    margin-left: -430px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 300px;
    height: auto;
    margin-top: -270px;
    margin-left: -530px;
  }
  @media(min-width: 1920px){
    width: 350px;
    height: auto;
    margin-top: -355px;
    margin-left: -630px;
  }
`
export const ImgHombreVolando = styled.img`
  width: 100%;
  height: 100%;
`
export const ContImgRino = styled.div`
  z-index: 99;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 160px;
    height: auto;
    margin-top: -220px;
    margin-left: -180px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 220px;
    height: auto;
    margin-top: -200px;
    margin-left: -290px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 300px;
    height: auto;
    margin-top: -270px;
    margin-left: -370px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 330px;
    height: auto;
    margin-top: 160px;
    margin-left: -430px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 440px;
    height: auto;
    margin-top: 110px;
    margin-left: -420px;
  }
  @media(min-width: 1920px){
    width: 550px;
    height: auto;
    margin-top: 110px;
    margin-left: -550px;
  }
`
export const ImgRino = styled.img`
  width: 100%;
  height: 100%;
`
export const ContImgReyna = styled.div`
  z-index: 99;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 70px;
    height: auto;
    margin-top: -300px;
    margin-left: 60px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 105px;
    height: auto;
    margin-top: -375px;
    margin-left: 130px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 140px;
    height: auto;
    margin-top: -505px;
    margin-left: 185px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 140px;
    height: auto;
    margin-top: -140px;
    margin-left: 220px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 160px;
    height: auto;
    margin-top: -170px;
    margin-left: 250px;
  }
  @media(min-width: 1920px){
    width: 200px;
    height: auto;
    margin-top: -230px;
    margin-left: 320px;
  }
`
export const ImgReyna = styled.img`
  width: 100%;
  height: 100%;
`
export const ContImgTriceratops = styled.div`
  z-index: 99;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 100px;
    height: auto;
    margin-top: -210px;
    margin-left: 76px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 170px;
    height: auto;
    margin-top: -148px;
    margin-left: 150px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 200px;
    height: auto;
    margin-top: -196px;
    margin-left: 220px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 220px;
    height: auto;
    margin-top: 210px;
    margin-left: 350px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 350px;
    height: auto;
    margin-top: 158px;
    margin-left: 300px;
  }
  @media(min-width: 1920px){
    width: 470px;
    height: auto;
    margin-top: 222px;
    margin-left: 350px;
  }
`
export const ImgTriceratops = styled.img`
  width: 100%;
  height: 100%;
`
