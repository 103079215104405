import React, {useState, useEffect} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import {Column, Columns} from 'bloomer';
import useSound from 'use-sound';
import {ContObraComp} from '../../ContObra';
import {BtnReplay} from '../../BtnReplay';
import {
  GlobalStylePage,
  ContArrow,
  ContImgHombreVolando,
  ImgHombreVolando,
  ContImgRino,
  ImgRino,
  ContImgReyna, ImgReyna, ContImgTriceratops,
} from './styles';
import {ArrowNavTop, ArrowNavRight, ArrowNavBottom, ArrowNavLeft} from '../../ArrowsNavigation';
import {GlobalStyle} from '../../../styles/GlobalStyles';
import {gsap} from "gsap";
import imgHombreVolando from '../../../images/obras/obra-2/hombre-volando.png';
import imgRino from '../../../images/obras/obra-2/rinoceronte.png';
import imgReyna from '../../../images/obras/obra-2/reyna.png';
import imgTriceratops from '../../../images/obras/obra-2/triceratops.png';
import soundHombrevolando from '../../../images/obras/obra-2/hombre-volando.wav';
import soundRino from '../../../images/obras/obra-2/rinoceronte.mp3';
import soundFanfar from '../../../images/obras/obra-2/fanfar.mp3';
import soundTriceratops from '../../../images/obras/obra-2/triceratops.mp3';
import {SpeechBubble} from '../../SpeechBubbles';
import {SpeechBubbleMini} from '../../SpeechBubbles/SpeechBubbleMini';
import {BtnParrilla} from '../../BtnParrilla';

const arNav = {
  top: null,
  right: 3,
  bottom: null,
  left: 1,
};

const Page02Comp = () => {
  const [showArrows, setShowArrows] = useState(false);
  const [initSpeechBubbleMini, setInitSpeechBubbleMini] = useState(false);
  const [initSpeechBubble, setInitSpeechBubble] = useState(false);
  const [initAnimatedMenFly, setInitAnimatedMenFly] = useState(false);
  const [initAnimatedRino, setInitAnimatedRino] = useState(false);
  const [initAnimatedReyna, setInitAnimatedReyna] = useState(false);
  const [initAnimatedTriceratops, setInitAnimatedTriceratops] = useState(false);
  const [initSoundMenFly, setInitSoundMenFly] = useState(false);
  const [initSoundRino, setInitSoundRino] = useState(false);
  const [initSoundReyna, setInitSoundReyna] = useState(false);
  const [initSoundTriceratops, setInitSoundTriceratops] = useState(false);
  const [reLaunchAll, setReLaunchAll] = useState(false);

  /*const data = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "obras/obra-1/obra.jpeg"}) {
        childImageSharp {
          fixed(width: 340, height: 670) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);*/
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "obras/obra-2/obra.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, maxHeight: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const mouseOver = () => {
    setShowArrows(true);
  };
  const mouseLeaver = () => {
    setShowArrows(false);
  };

  const fnReLaunchAll = () => {
    fnReLaunchMenFly(0);
    fnReLaunchRino(0);
    fnReLaunchReyna(0);
    fnReLaunchTriceratops(0);
    setReLaunchAll(!reLaunchAll);
  }

  const fnReLaunchMenFly = (noReplay = 1) => {
    setInitAnimatedMenFly(false);
    setInitSoundMenFly(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedMenFly(true);
      }, 1000);
    }
  };
  const fnReLaunchRino = (noReplay = 1) => {
    setInitAnimatedRino(false);
    setInitSoundRino(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedRino(true);
      }, 1000);
    }
  };
  const fnReLaunchReyna = (noReplay = 1) => {
    setInitAnimatedReyna(false);
    setInitSoundReyna(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedReyna(true);
      }, 1000);
    }
  };
  const fnReLaunchTriceratops = (noReplay = 1) => {
    setInitAnimatedTriceratops(false);
    setInitSoundTriceratops(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedTriceratops(true);
      }, 1000);
    }
  };

  const fnAnimateMenFly = () => {
    return (
      <ContImgHombreVolando className={'img-animated'} onClick={() => fnReLaunchMenFly()}>
        {
          initAnimatedMenFly &&
          <ImgHombreVolando
            id={'animate-hombre-volando'}
            className={'animate-hombre-volando'}
            src={imgHombreVolando}
            alt="Hombre"
          />
        }
      </ContImgHombreVolando>
    )
  }

  const fnAnimateRino = () => {
    return (
      <ContImgRino className={'img-animated'} onClick={() => fnReLaunchRino()}>
        {
          initAnimatedRino &&
          <ImgRino
            id={'animate-rino'}
            className={'animate-rino'}
            src={imgRino}
            alt="Rinoceronte"
          />
        }
      </ContImgRino>
    )
  }

  const fnAnimateReyna = () => {
    return (
      <ContImgReyna className={'img-animated'} onClick={() => fnReLaunchReyna()}>
        {
          initAnimatedReyna &&
          <ImgReyna
            id={'animate-reyna'}
            className={'animate-reyna'}
            src={imgReyna}
            alt="Reyna"
          />
        }
      </ContImgReyna>
    )
  }

  const fnAnimateTriceratops = () => {
    return (
      <ContImgTriceratops className={'img-animated'} onClick={() => fnReLaunchTriceratops()}>
        {
          initAnimatedTriceratops &&
          <ImgReyna
            id={'animate-triceratops'}
            className={'animate-triceratops'}
            src={imgTriceratops}
            alt="Triceratops"
          />
        }
      </ContImgTriceratops>
    )
  }

  const fnSpeechBubbleMini = () => {
    return (
      <>
        {
          initSpeechBubbleMini &&
          <SpeechBubbleMini
            id={'speech-bubble-mini'}
            className={'speech-bubble-mini'}
            onClick={() => setInitSpeechBubble(true)}
          />
        }
      </>
    )
  }
  const fnSpeechBubble = () => {
    return (
      <>
        {
          initSpeechBubble &&
          <SpeechBubble
            id={'speech-bubble'}
            className={''}
            classClose={'close-top-left'}
            onClick={() => setInitSpeechBubble(false)}
          >
            <div>No en el esquema de generación.</div>
            <div>¿Dónde está el verano, el inimaginable verano Zero?</div>
          </SpeechBubble>
        }
      </>
    )
  }


  const [playHombreVolando, { stopHombreVolando }] = useSound(
    soundHombrevolando,
    { volume: 0.3 }
  );
  const [playRino, { stopRino }] = useSound(
    soundRino,
    { volume: 0.3 }
  );
  const [playReyna, { stopReyna }] = useSound(
    soundFanfar,
    { volume: 0.1 }
  );
  const [playTriceratops, { stopTriceratops }] = useSound(
    soundTriceratops,
    { volume: 0.3 }
  );

  useEffect(() => {

    setTimeout(function (){
      setInitAnimatedMenFly(true);
    }, 3000);

    setTimeout(function (){
      setInitAnimatedRino(true);
    }, 6000);

    setTimeout(function (){
      setInitAnimatedReyna(true);
    }, 9000);

    setTimeout(function (){
      setInitAnimatedTriceratops(true);
    }, 12000);

    setTimeout(function (){
      setInitSpeechBubbleMini(true);
    }, 15000);

  },[reLaunchAll]);

  useEffect(() => {
    const windowWrap = gsap.utils.wrap(0, window.innerWidth);

    if(initAnimatedMenFly){
      gsap.to(".animate-hombre-volando", {
        x: 100,
        modifiers: {
          x: x => windowWrap(parseFloat(x)) + "px"
        },
        rotation: function(i, target, list) {
          return -60;
        },
      });
      if(!initSoundMenFly){
        playHombreVolando();
        setInitSoundMenFly(true);
      }
    }

    if(initAnimatedRino){
      gsap.to(".animate-rino", {
        x: 100,
        modifiers: {
          x: x => windowWrap(parseFloat(x)) + "px"
        },
        rotation: function(i, target, list) {
          return 10;
        },
      });
      if(!initSoundRino){
        playRino();
        setInitSoundRino(true);
      }
    }

    if(initAnimatedReyna){
      gsap.to(".animate-reyna", {
        y: 0,
        modifiers: {
          y: y => windowWrap(parseFloat(y)) + "px"
        },
        opacity: 1,
      });
      gsap.to(".animate-reyna", {
        opacity: 0,
        delay: 3,
        duration: 3,
      });
      if(!initSoundReyna){
        playReyna();
        setInitSoundReyna(true);
      }
    }

    if(initAnimatedTriceratops){
      gsap.to(".animate-triceratops", {
        y: 0,
        modifiers: {
          y: y => windowWrap(parseFloat(y)) + "px"
        },
      });
      if(!initSoundTriceratops){
        playTriceratops();
        setInitSoundTriceratops(true);
      }
    }

  }, [initAnimatedMenFly, initAnimatedRino, initAnimatedReyna, initAnimatedTriceratops]);

  return (
    <ContObraComp>
      <BtnParrilla/>
      <BtnReplay onClick={() => fnReLaunchAll()}/>
      <div className={'columns-padding-no cont-obra-height'} onMouseOver={mouseOver} onMouseLeave={mouseLeaver}>
        <GlobalStyle/>
        <GlobalStylePage/>

        <Columns className="columns-responsive" isMultiline={false} isCentered={true}>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavTop toPage={arNav.top}/>}
            </ContArrow>
          </Column>
        </Columns>

        <Columns className="columns-responsive columns-obra-height flex-center" isMultiline={false} isCentered={true}>

          <Column isSize={{mobile: 1, tablet: 1, desktop: 1, widescreen: 1}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavLeft toPage={arNav.left}/>}
            </ContArrow>
          </Column>

          <Column isSize={{mobile: 10, tablet: 10, desktop: 10, widescreen: 10}} className={'flex-center container-animated'}>
            {/*<Img fixed={data.file.childImageSharp.fixed} />*/}
            <Img className={'img-obra'} fluid={data.placeholderImage.childImageSharp.fluid}/>
            {fnAnimateMenFly()}
            {fnAnimateRino()}
            {fnAnimateReyna()}
            {fnAnimateTriceratops()}
            {fnSpeechBubbleMini()}
            {fnSpeechBubble()}
          </Column>

          <Column isSize={{mobile: 1, tablet: 1, desktop: 1, widescreen: 1}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavRight toPage={arNav.right}/>}
            </ContArrow>
          </Column>

        </Columns>

        <Columns className="columns-responsive" isMultiline={false} isCentered={true}>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavBottom toPage={arNav.bottom}/>}
            </ContArrow>
          </Column>
        </Columns>
      </div>
    </ContObraComp>
  );
};

export default Page02Comp;
